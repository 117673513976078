import restClient from "./restConfig";
import { getAuthToken } from "../../util/securityUtil";
import { showError } from "../../components/Toast/Toaster";

const setupAxiosInterceptors = (onUnauthenticated) => {
  const onRequestSuccess = (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = (response) => {
    if (response.data && response.data.content) {
      if (response.data.content instanceof Array) {
        response.data.content.forEach((item) => {
          Object.keys(item).forEach((key) => {
            if (item[key] === null || item[key] === undefined) {
              item[key] = "";
            }
          });
        });
      }
    }
    return response;
  };
  const onResponseError = (err) => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      onUnauthenticated();
    }
    if (err.response && err.response.data && err.response.data.message) {
      let errorMessage = err.response.data.message;
      if (errorMessage.endsWith(':')) {
        errorMessage = errorMessage.slice(0, -1);
      }
      if (errorMessage.includes("ModelMapper mapping errors")) {
        showError({ title: "Hata!", body: "Geçersiz veya hatalı bir ID girdiniz!" });
      }
       else if (errorMessage !== "Hasta bulunamadı!") {  // backend kodu hata mesajına bağlı
        showError({
          body: errorMessage,
        });
      }
    }
    return Promise.reject(err);
  };
  restClient.interceptors.request.use(onRequestSuccess);
  restClient.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
